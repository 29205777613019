.popup_card{
    background-color: white;
    border-radius: 17px;
    border: 1px solid #D1D2D3;
    width: 100%;
    height: 62vh;
    padding-left: 1%;
    padding-right: 1%;
    margin: 0;
}
.popup_card-block{
    background-color: white;
    border-radius: 13px;
    border: 1px solid #D1D2D3;
    width: 100%;
    padding-left: 3%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-right: 1%;
    margin: 0;
}
.row_input_title{
    font-weight: bold;
    font-size: 18px;
}
.pregnant_modal_popup{
    width: 100%;
}
