.header{
    font-size: 16px;
    padding: 0;
    margin-bottom: 1vh;
    margin-top: 1vh;
    /* height: 7vh; */
    width: inherit !important;
    /* padding: 0 100px 100px 0; */
    display: flex;
    justify-content: space-between;
    
    /* align-items: center; */
}
#header_row{
    height: 6.2vh;
    display: flex; 
    /* border-bottom: 1px solid rgb(195, 193, 192); */
    width: inherit !important;
    padding-top: 1vh; 
    padding-bottom: 1vh; 
    gap: 1%;
    justify-content: space-between;
}

#header_row > div{

    width: 33%;
    display: flex;
    gap: 1%;
    /* justify-content: center; */
}
.MuiButtonBase-root{
    font-size: inherit;
}
#week_num{
    background-color: #31BD58; 
    width: 30%;
    border-radius: 5.76px;
    height: 45px;
    color: white;
    padding: 0.7vh;
    display: flex;
    gap: 5%;
    padding: 0 !important;
    margin: 0 !important;
    vertical-align: middle;
    justify-content: center;
    text-transform: none;
    vertical-align: middle;
    
}
#week_num > #num{
    font-size: 32px;
    padding: 0 !important;
    margin: 0 !important;
    font-weight: bolder;
    height: inherit;
    vertical-align: middle;
}
#week_num > #week{
    font-size: 23px;
    height: inherit;
}
#select_call_target{
    width: 100%;
    font-size: inherit;
}
#header_pregnant_info > #week_num{
    /* width: 20%; */
}
#header_pregnant_info > *{
    margin-bottom: 0;
}
#header_call_actions > *{
    width: 50%;
}
#header_number_select > div > div{
    height: 100%;
}
.MuiOutlinedInput-notchedOutline{
    /* display: none; */
}
#call_button{
    background-color: #31BD58;
    text-transform: none;
    font-size: 20px;
    font-weight: unset;
}
#videocall_button{
    text-transform: none;
    font-size: 20px;
    font-weight: unset;
}