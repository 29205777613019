.main_block_title{
    font-size: 20px;
    font-weight: bold;
}
.pregnant_card_sidebar_effect-card{
    overflow-y: auto;
    height: 59vh;
}
.pregnant_card_popup_card_main_form{
    margin-top: 2%;
    height: 20vh;
}
.popup_card{
    height: 59vh;
}

.pregnant_card_popup_card_comment > textarea{
    height: 17vh !important;
    margin-top: 1.5%;
    border-radius: 14px;
    width: 100%;
    padding: 1% !important;
    resize: none;
    border-color: #CBCDD2 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.pregnant_card_popup_card_main_form, textarea:focus
{
    -webkit-appearance: none;
    -moz-apperarance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding: 0px;
    resize: none;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}
.main_block_form_row{
    margin-top: 1vh;
    /* margin-bottom: 2vh; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 3%;
}
.main_block_form_row > div{
    text-align: left;
    margin-bottom: 2.5vh;
}
.main_block{
    padding: 1%;
}
.anamnesis_small_input{
    width: 80%;
}
.main_block_form_row_input_title{
    max-height: 3em;
    text-align: start;
}
/* width */
.align-items-bottom{
    align-items: flex-start !important;
}