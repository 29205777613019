#pregnant_card_sidebar{
    height: 100%;
}
#pregnant_card_sidebar > div{
    background-color: white !important;
    padding: 1vh;
}
.sidebar_button.active{
    color: white !important;
    background-color: #2F76E6 !important;
}
.sidebar_button{
    margin-top: 1vh !important;
    width: 100%;
    font-size: 16px !important;
    text-transform: none !important;
    font-weight: bold !important;
    color: #45536C !important;
    background-color: #EBEEEF !important;
}
.sidebar_button:hover{
    color: white !important;
    background-color: #2F76E6 !important;
}
.sidebar_title{
    font-size: 22px;
    font-weight: bold;
    text-align: start;
}
.sidebar_close_button{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.sidebar_close_button > svg{
    height: 27px;
    width: 27px;
    display: flex;
    color: #45536C;
    justify-content: flex-end;
}