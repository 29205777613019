.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}
.MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation1 .css-1ps6pg7-MuiPaper-root{
    font-size: 0.875rem;
}
.MuiDataGrid-columnHeader--sorted{
    border: unset !important;
}
.MuiDataGrid-root{
    border: none !important;
}
.MuiDataGrid-columnHeaders{
    min-height: 40px !important;
    max-height: 40px !important;
    line-height: 40px !important;
    background-color: #E9E9E9;
    border-radius: 4px;
}
.MuiDataGrid-columnHeaderTitle{
    font-weight: bold !important;
    font-size: 18px !important;
}
.MuiDataGrid-cellContent{
    font-size: 16px !important;
}