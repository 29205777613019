.pregnant_card_block{
    height: 100%;
    width: 33%;
    border: 1px solid #DDDEDE;
    border-radius: 0 !important;
    box-shadow: none !important;
}
.page_row{
    width: inherit;
    margin-bottom: 1.15vh;
    height: 44vh;
    display: flex;
    gap: 0.5%;
    justify-content: center;
}
.block_row{
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 2%;
    flex: 1 1 0;
    margin-top: 1vh ; 
}
.block_row > div{
    width: 49%; 
}
.field_title{
    color: #000000;
    font-weight: bold;
    font-size: 17px;
    text-align: start;
}
.field_value{
    font-size: 16px;
    color: #000000;
    text-align: start;
}
.button_row{
    display: flex; 
    justify-content: flex-end;
    padding-right: 5%;
    gap: 1%;
    margin-top: 1vh;
}
.block_title{
    text-align: start;
    padding: 2.7% 0 0 4.65% !important;
    /* border-bottom: 1px solid rgb(195, 193, 192); */
}
.block_title > div > span{
    font-size: 21px !important;
    font-weight: bold;
}

.MuiCardContent-root{
    padding-top: 0 !important;
}
.MuiFormLabel-root{
    margin-top: -8px;
}
