.pregnant_modal{
    z-index: 8;
    position: fixed;
    width: 95%;
    height: 90vh;
    top: 7vh;
    display: flex;
    justify-content: space-around;
    -webkit-box-shadow: 0px 0px 19px 4px rgba(182, 182, 182, 0.2);
    -moz-box-shadow: 0px 0px 19px 4px rgba(182, 182, 182, 0.2);
    box-shadow: 0px 0px 19px 4px rgba(182, 182, 182, 0.2);
    background-color: white;
    left: calc(6.7% + 24px) !important;
    right: 24px;
    width: calc(100% - 6.7% - 48px);
}
.pregnant_modal_content{
    padding-top: 2%;
    padding-bottom: 1%;
    padding-left: 1%;
    width: 80%;
    height: 100%;
    z-index: inherit;
}
.pregnant_modal_popup{
    height: 100%;
}
.pregnant_modal_sidebar{
    z-index: inherit;
    width: 20%;

}
.pregnant_modal_sidebar > aside{
    z-index: inherit;
    width: 100%;
}
.pregnant_modal_sidebar > aside > div{
    padding-right: 6% !important;
    padding-left: 6% !important;
}
