.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}
.MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation1 .css-1ps6pg7-MuiPaper-root{
    font-size: 0.875rem;
}
.MuiDataGrid-columnHeader--sorted{
    border: unset !important;
}
.MuiDataGrid-root{
    border: none !important;
}
#complications_table{
    height: 32.3vh;
}